import { makeStyles, Theme } from '@material-ui/core/styles'
import vrStyles from '@src/styles/utils/vrStyles'

const { vr2, vr4, vr6 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  vr2,
  vr4,
  vr6,
  countrySelector: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 5, 0, 2),
    color: theme.palette.brand.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    borderRight: `2px solid rgba(0, 0, 0, 0.25)`,
  },
  languageSelector: {
    padding: theme.spacing(0, 0, 0, 5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.brand.main,
  },
  languageIcon: {
    color: theme.palette.brand.main,
    marginRight: '.5rem',
    marginLeft: '.2rem',
    height: '1rem',
    width: '1rem',
  },
  popoverRoot: {
    top: '1.9rem !important',
    zIndex: '900 !important' as any,
  },
  closeIconButton: {
    color: theme.palette.common.black,
    position: 'absolute',
    right: '4rem',
    top: '1.2rem',
  },
  closeIcon: {
    height: theme.typography.pxToRem(27),
    width: theme.typography.pxToRem(27),
  },
  heading: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  fontFourteen: {
    fontSize: theme.typography.pxToRem(14),
  },
  languageMenu: {
    marginTop: theme.spacing(2),
    minWidth: theme.typography.pxToRem(150),
    position: 'relative',
    zIndex: '2500 !important' as any,
  },
  languageMenuItem: {
    fontSize: theme.typography.pxToRem(14),
    paddingRight: theme.spacing(5),
    minHeight: 40,
  },
  learnMoreLink: {
    padding: theme.spacing(2, 0, 2, 6),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(6),
  },
  menusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
  },
  singleMenuContainer: {
    height: 'inherit',
    width: '50%',
    maxWidth: theme.typography.pxToRem(290),
    minWidth: theme.typography.pxToRem(140),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  locationMenu: {
    marginLeft: theme.spacing(10),
  },
  selectMenu: {
    border: `1px solid ${theme.palette.grey[600]}`,
    overflowY: 'scroll',
    height: theme.typography.pxToRem(182),
    maxWidth: theme.typography.pxToRem(290),
    padding: 0,
    borderRadius: '3px',
  },
  regionSelectMenu: {
    overflowY: 'hidden',
  },
  locationDisclaimer: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    lineHeight: '1.43',
  },
  continueButton: {
    alignSelf: 'flex-end',
    width: theme.typography.pxToRem(144),
    height: theme.typography.pxToRem(40),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export default useStyles
