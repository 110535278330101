import Icon, { BaseIconProps } from './Icon'
function CaretDownIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 11" {...props}>
        <path d="M10 11a2.22 2.22 0 0 1-1.446-.531L.776 3.871A2.186 2.186 0 0 1 .535.768 2.243 2.243 0 0 1 3.669.53L10 5.9 16.331.53a2.24 2.24 0 0 1 3.134.238 2.186 2.186 0 0 1-.241 3.1l-7.778 6.6A2.22 2.22 0 0 1 10 11" />
      </svg>
    </Icon>
  )
}
export default CaretDownIcon
