import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(0, 2, 0, 0),
  },
  icon: {
    borderRadius: 3,
    border: `1px solid ${theme.palette.grey[500]}`,
    width: 18,
    height: 18,
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: theme.palette.common.white,
    },
  },
  checkedIcon: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232376b1'/%3E%3C/svg%3E\")",
      content: '""',
    },
    '&hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const DisclaimerCustomCheckbox = (props) => {
  const classes = useStyles()
  return (
    <Checkbox
      className={classes.root}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}

export default DisclaimerCustomCheckbox
