import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRouter } from '@src/routes'
import {
  Box,
  Button,
  ButtonBase,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core'
import { CountryResponse } from '@src/types/graphql-types'
import messages from '@utils/messages'
import { MS_COUNTRIES } from '@src/utils/useCompanyName'
import { getLocalizedUrl } from '@utils/regional'
import { useUserSession } from '@utils/useUserSession'
import DisclaimerCustomCheckbox from '@src/components/Header/CountryAndLanguageMenu/DisclaimerCustomCheckbox'
import useStyles from '@src/components/Header/CountryAndLanguageMenu/styles'
import { setLastReferrerUrl } from '@utils/analytics'
import LiquidSelect from '../LiquidSelect'
import LiquidFormLabel from '../LiquidFormLabel'
import hasTouch from '@src/styles/utils/hasTouch'
import countryRegionMap from '@localization/country-regions.json'
import { useCountriesData } from '@src/utils/useCountriesData'
import { sendEvent } from '@src/utils/analytics/coreAnalytics'
const nonDisclaimerCountries = MS_COUNTRIES

const getCountriesForRegion = (regionsData, regionCode) => {
  regionsData.forEach((region) => {
    region.countries.sort((a, b) => {
      const sortByA =
        a.displayName && b.displayName ? a.displayName : a.countryCode
      const sortByB = b.displayName || b.countryCode
      return sortByA > sortByB ? 1 : -1
    })
  })
  return regionsData.find((regionData) => regionData.regionCode === regionCode)
}
interface CountryRegionSelectionProps {
  mobileDisplay?: boolean
}

export const CountryRegionSelection: React.FC<CountryRegionSelectionProps> = ({
  mobileDisplay = false,
}) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { userSession, userSessionActions } = useUserSession()
  const { getCountryProp, getCountryDetails } = useCountriesData()

  const regionsData = getCountryDetails(userSession.country)
  /* returns a single country object that matches the user country*/
  const [countrySelection, setCountrySelection] = useState<string | undefined>(
    userSession.country
  )
  const [regionSelection, setRegionSelection] = useState<
    string | undefined | null
  >()
  const [regionCountries, setRegionCountries] = useState<CountryResponse[]>()
  const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false)
  const onLocationSelectPage = router.pathname.includes('/location-select')

  // initializes user region if they already have their country defined
  useEffect(() => {
    if (countrySelection && !regionSelection) {
      setRegionSelection(regionsData?.regionCode)
    }
  }, [countrySelection, regionsData, regionSelection])

  // initialize country list if region is defined
  useEffect(() => {
    if (regionSelection && countryRegionMap) {
      const userRegionData = getCountriesForRegion(
        countryRegionMap,
        regionSelection
      )
      setRegionCountries(userRegionData.countries)
    }
  }, [regionSelection, countryRegionMap])

  // Auto-selects country on mobile when the region changes.
  // Without this, since mobile uses native dropdowns, it'll *look* like a
  // country is selected, but no country is actually selected.
  useEffect(() => {
    if (mobileDisplay && regionCountries) {
      const countryCodes = regionCountries.map((c) => c.countryCode)
      setCountrySelection((currentSelection) => {
        if (currentSelection && countryCodes.includes(currentSelection)) {
          return currentSelection
        }
        if (countryCodes.includes(userSession.country)) {
          return userSession.country
        }
        return countryCodes[0]
      })
    }
  }, [mobileDisplay, regionCountries, userSession.country])

  const handleRegionSelect = (e) => {
    e.preventDefault()
    const regionCode = e.currentTarget.value || e.currentTarget.dataset.value

    if (regionCode) {
      const selectedRegion = getCountriesForRegion(countryRegionMap, regionCode)
      setRegionSelection(regionCode)
      setRegionCountries(selectedRegion?.countries)
    }
  }

  const handleSubmit = () => {
    const { redirect = '/' } = router.query
    sendEvent({
      payload: {
        event: 'navigation_interaction',
        action: 'header click',
        component: 'modal',
        core_event: 'no',
        detail: 'change country',
        element_text: 'continue',
        element_type: 'button',
        event_group: undefined,
        event_subgroup: undefined,
        link_url: undefined,
        section: 'change location',
        user_detail: undefined,
      },
    })
    if (countrySelection) {
      const selectedCountryLanguages = getCountryProp(
        countrySelection,
        'supportedLanguages'
      )
      /**
       * if the available languages for the selected country doesn't
       * include the users current language, set to first valid language
       * in selected country's array
       */
      /*  Changed IF statement logic since english language availabe for all coutries(regarding above comment)
      previously it was if (!selectedCountryLanguages.includes(userSession.language)) */
      if (selectedCountryLanguages && selectedCountryLanguages.length) {
        userSessionActions.setLanguage(selectedCountryLanguages[0])
      }

      const updatedUserSession = userSessionActions.setCountry(countrySelection)
      if (onLocationSelectPage) {
        const url = getLocalizedUrl(updatedUserSession, redirect)
        // Manually redirect the user to the index page of their chosen country
        // We cannot use next router here because of cookie complications
        window.location.href = url.as
        setLastReferrerUrl()
      } else {
        window.location.reload()
      }
    }
  }

  const renderSelectOption = (value: string, label: string) => {
    if (hasTouch) {
      return (
        <option key={value} value={value}>
          {label}
        </option>
      )
    }

    return (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    )
  }

  const outsideOfUsAndCanada =
    !!countrySelection && !nonDisclaimerCountries.includes(countrySelection)
  const classes = useStyles()

  if (!mobileDisplay) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        height="50vh"
        minHeight="30rem"
        justifyContent="center"
      >
        {' '}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="50%"
          maxWidth="42rem"
          mt="2.5rem"
          padding="0 1.5rem"
        >
          <Box display="flex" alignSelf="flext-start" flexDirection="column">
            <h1 className={classes.heading}>
              {formatMessage({
                id: 'CHANGE_LOCATION',
                defaultMessage: 'Change Location',
              })}
            </h1>
            <p className={classes.fontFourteen}>
              {formatMessage(messages.SELECT_REGION_AND_LOCATION)}
            </p>
          </Box>
          <div className={classes.menusContainer}>
            {/**Region Menu */}
            <Box display="flex">
              <div className={classes.singleMenuContainer}>
                <span className={classes.fontFourteen}>
                  {formatMessage({ id: 'REGION', defaultMessage: 'Region' })}
                </span>
                <MenuList
                  className={clsx(classes.selectMenu, classes.regionSelectMenu)}
                >
                  {countryRegionMap &&
                    countryRegionMap.map(({ regionCode, regionName }) => (
                      <MenuItem
                        key={regionCode}
                        data-value={regionCode}
                        onClick={handleRegionSelect}
                        selected={regionCode === regionSelection}
                        data-testid={`change-region-${regionCode}`}
                        id={`change-region-${regionCode}`}
                      >
                        {regionName}
                      </MenuItem>
                    ))}
                </MenuList>
              </div>
              {/**Location Menu */}
              <div
                className={clsx(
                  classes.singleMenuContainer,
                  classes.locationMenu
                )}
              >
                <span className={classes.fontFourteen}>
                  {formatMessage(messages.LOCATION)}
                </span>
                <MenuList className={classes.selectMenu}>
                  {regionCountries &&
                    regionCountries.map(({ displayName, countryCode }) => (
                      <MenuItem
                        key={countryCode}
                        data-value={countryCode}
                        onClick={() => setCountrySelection(countryCode)}
                        selected={countryCode === countrySelection}
                        data-testid={`change-location-${countryCode}`}
                        id={`change-location-${countryCode}`}
                      >
                        {displayName}
                      </MenuItem>
                    ))}
                </MenuList>
              </div>
            </Box>
            {outsideOfUsAndCanada && !onLocationSelectPage && (
              <Box mb={4} display="flex" fontSize={12}>
                <Box justifySelf="flex-start">
                  <DisclaimerCustomCheckbox
                    checked={acceptedDisclaimer}
                    onChange={() => setAcceptedDisclaimer(!acceptedDisclaimer)}
                    data-testid="location-disclaimer-checkbox"
                    id="location-disclaimer-checkbox"
                  />
                </Box>
                <ButtonBase
                  onClick={() => setAcceptedDisclaimer(!acceptedDisclaimer)}
                  className={classes.locationDisclaimer}
                >
                  {formatMessage({
                    id: 'COUNTRY_DISCLAIMER',
                    defaultMessage:
                      'In the United States and Canada we operate as MilliporeSigma. Please confirm that you are not located in one of these locations and you intend to visit our sites outside these locations by checking the box to the left and clicking Continue.',
                  })}
                </ButtonBase>
              </Box>
            )}
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.continueButton}
              disabled={
                !countrySelection ||
                (outsideOfUsAndCanada &&
                  !acceptedDisclaimer &&
                  !onLocationSelectPage)
              }
              data-testid="change-region-and-location-submit"
              id="change-region-and-location-submit"
            >
              {formatMessage(messages.CONTINUE)}
            </Button>
          </div>
          {onLocationSelectPage && (
            <Box mt={10} fontSize={12} mb={4}>
              {formatMessage({
                id: 'COUNTRY_LEGAL_STATEMENT',
                defaultMessage:
                  '© 2020  Merck KGaA, Darmstadt, Germany and/or its affiliates. All Rights Reserved. Reproduction of any materials from the site is strictly forbidden without permission.',
              })}
            </Box>
          )}
        </Box>
      </Box>
    )
  } else {
    return (
      <div>
        {regionSelection !== undefined && (
          <>
            <LiquidFormLabel>
              <Typography variant="body2" component="label">
                <FormattedMessage id="REGION" defaultMessage="Region" />
              </Typography>
            </LiquidFormLabel>
            <LiquidSelect
              className={classes.vr4}
              onChange={handleRegionSelect}
              native={hasTouch}
              value={regionSelection}
              id="region-select"
            >
              {countryRegionMap &&
                countryRegionMap.map(({ regionCode, regionName }) =>
                  renderSelectOption(regionCode, regionName)
                )}
            </LiquidSelect>

            <LiquidFormLabel>
              <Typography variant="body2" component="label">
                <FormattedMessage {...messages.LOCATION} />
              </Typography>
            </LiquidFormLabel>
            <LiquidSelect
              className={classes.vr6}
              onChange={(e) => {
                setCountrySelection(e.target.value as string)
              }}
              value={countrySelection}
              id="region-select"
              native={hasTouch}
            >
              {regionCountries &&
                regionCountries.map(({ displayName, countryCode }) =>
                  renderSelectOption(countryCode, displayName)
                )}
            </LiquidSelect>

            {outsideOfUsAndCanada && !onLocationSelectPage && (
              <Box mb={4} display="flex" fontSize={12}>
                <Box justifySelf="flex-start">
                  <DisclaimerCustomCheckbox
                    checked={acceptedDisclaimer}
                    onChange={() => setAcceptedDisclaimer(!acceptedDisclaimer)}
                    data-testid="location-disclaimer-checkbox"
                    id="location-disclaimer-checkbox"
                  />
                </Box>
                <ButtonBase
                  onClick={() => setAcceptedDisclaimer(!acceptedDisclaimer)}
                  className={classes.locationDisclaimer}
                >
                  {formatMessage({
                    id: 'COUNTRY_DISCLAIMER',
                    defaultMessage:
                      'In the United States and Canada we operate as MilliporeSigma. Please confirm that you are not located in one of these locations and you intend to visit our sites outside these locations by checking the box to the left and clicking Continue.',
                  })}
                </ButtonBase>
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
              disabled={
                !countrySelection ||
                (outsideOfUsAndCanada &&
                  !acceptedDisclaimer &&
                  !onLocationSelectPage)
              }
              data-testid="change-region-and-location-submit"
            >
              {formatMessage(messages.SAVE_LOCATION)}
            </Button>
          </>
        )}
      </div>
    )
  }
}

export default CountryRegionSelection
