/**
 * This util is used on the client only - acts as feature detection for touch devices.
 * Should be used to progressively enhance UI features which should incorporate touch
 * specific behaviors.
 */
const hasTouch: boolean =
  (typeof window !== 'undefined' && 'ontouchstart' in window) ||
  (typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0)

export default hasTouch
