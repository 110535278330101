import React from 'react'
import { MenuItem } from '@material-ui/core'
import hasTouch from '@src/styles/utils/hasTouch'

/*
 * This needs to be a function that exports JSX or else MUI
 * removes all the click functionality
 */
const liquidSelectOptionRenderer = (
  key: string | number,
  val: string,
  label: string
) => {
  if (hasTouch) {
    return (
      <option key={key} value={val}>
        {label}
      </option>
    )
  }
  return (
    <MenuItem key={key} value={val}>
      {label}
    </MenuItem>
  )
}

export default liquidSelectOptionRenderer
